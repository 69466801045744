import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import Icon from '@shared/ui/icons/Icon';
const IconVariantCss = () => `
    & .MuiButton-startIcon {
        margin: 0;
    }
`;
const SelectedCss = (props) => `
        color: ${props.theme.palette.primary.main};
        border: 1px solid ${props.theme.palette.primary.main};
        background-color: ${props.theme.customColors.shades.primaryLight};
        box-shadow: 0px 0px 0px 2px ${props.theme.customColors.button.pill.shadow};
    `;
const HoverCss = (props) => {
    if (props.selected) {
        return `
            &:hover {
                ${SelectedCss(props)}
            }
        `;
    }
    return `
            &:hover {
                color: ${props.theme.palette.text.primary};
                border-color: ${props.theme.palette.primary.main};
                background-color: ${props.theme.customColors.surface.surface};
            }
        `;
};
const ActiveCss = (props) => {
    if (props.selected) {
        return '';
    }
    return `
        &:active {
            border-width: 2px;
            margin: -1px;
        }
    `;
};
const ButtonStyled = styled(Button) `
    color: ${props => props.theme.palette.text.secondary};
    background-color: ${props => props.theme.customColors.surface.surface};
    border-radius: 6px; // global border radius is set to 4px from other layouts. // TODO: discuss
    border: 1px solid ${props => props.theme.customColors.button.pill.border};

    ${props => HoverCss(props)}
    ${props => ActiveCss(props)}

    ${props => props.selected && SelectedCss(props)}

    // styles that fixes the icon display issues
    min-width: auto;
    padding: ${props => props.theme.spacing_sizes.m}px;

    ${props => props.variant === 'pill-icon' && IconVariantCss()}
`;
const Pill = ({ IconComponent, selected = false, onClick = () => { } }) => (_jsx(ButtonStyled, { variant: 'pill-icon', selected: selected, startIcon: (_jsx(Icon, { size: 16, IconComponent: IconComponent })), disableElevation: true, onClick: onClick }));
export default Pill;
