import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ContainerColumn } from '../../../../styled';
import Icon from '../../../../../shared/ui/icons/Icon';
const NavigationButtonContainer = styled(ContainerColumn, { shouldForwardProp: (propName) => propName !== 'display' && propName !== 'disabled' })((props) => ({
    width: 'auto',
    height: 'auto',
    cursor: (props.display && !props.disabled) ? 'pointer' : 'inherit',
    visibility: props.display ? 'visible' : 'hidden',
}));
const CarouselNavigationButton = ({ display = true, disabled, onClick, IconComponent, }) => {
    const theme = useTheme();
    const handleOnClick = () => {
        if (display && !disabled) {
            onClick();
        }
    };
    return (_jsx(NavigationButtonContainer, { display: display, disabled: disabled, onClick: handleOnClick, children: _jsx(Icon, { color: theme.palette.text.secondary, size: 16, IconComponent: IconComponent }) }));
};
export default CarouselNavigationButton;
