import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as CircleIcon } from '@icons/custom/circle.svg';
import { ContainerColumn } from '../../../../styled';
import Icon from '../../../../../shared/ui/icons/Icon';
const PaginationIcon = styled(Icon, { shouldForwardProp: (propName) => propName !== 'selected' && propName !== 'disabled' })((props) => ({
    cursor: (props.selected || props.disabled) ? 'inherit' : 'pointer',
    color: props.selected ?
        props.theme.palette.primary.main :
        props.theme.customColors.pagination.regular,
}));
const PaginationButtonContainer = styled(ContainerColumn) `
    width: auto;
    height: auto;
`;
const CarouselPaginationButton = ({ selected, disabled, onClick, }) => {
    const handleOnClick = () => {
        if (!disabled) {
            onClick();
        }
    };
    return (_jsx(PaginationButtonContainer, { onClick: handleOnClick, children: _jsx(PaginationIcon, { size: 8, selected: selected, disabled: disabled, IconComponent: CircleIcon }) }));
};
export default CarouselPaginationButton;
